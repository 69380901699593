import React, { useEffect, useState } from "react"
import BrowserLink from "../../BrowserLink"
import * as css from "./SubMenu.module.css"

const TrainingMenu = ({ menu }) => {
  const [selected, setSelected] = useState([0, 0])
  const isSelected = (groupIndex, itemIndex) =>
    groupIndex === selected[0] && itemIndex === selected[1]

  return (
    <div className={css.menu}>
      <div className={css.groups}>
        {Object.keys(menu).map((groupTitle, groupIndex) => {
          return (
            <div key={`group-${groupIndex}`} className={css.group}>
              <div className={css.groupTitle}>{groupTitle}</div>
              <ul className={css.items}>
                {menu[groupTitle].map((item, itemIndex) => {
                  return (
                    <li
                      key={`tab-${groupIndex}-${itemIndex}`}
                      className={
                        isSelected(groupIndex, itemIndex) ? css.isSelected : ""
                      }
                      onMouseEnter={e => setSelected([groupIndex, itemIndex])}
                    >
                      <BrowserLink href={item.link} source="header__submenu">
                        <span>{item.title}</span>
                      </BrowserLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
      <div className={css.content}>
        {Object.keys(menu).map((groupTitle, groupIndex) => {
          return menu[groupTitle].map((slide, slideIndex) => {
            return (
              <div
                key={`slide-${groupIndex}-${slideIndex}`}
                className={
                  isSelected(groupIndex, slideIndex)
                    ? `${css.isSelected} ${css.slide}`
                    : css.slide
                }
              >
                <p>
                  <img src={slide.image} alt={slide.title} />
                </p>
                <p>
                  <BrowserLink href={slide.link} source="header__submenu__content">
                    <span>Learn More</span>
                  </BrowserLink>
                </p>
              </div>
            )
          })
        })}
      </div>
    </div>
  )
}

export default TrainingMenu
