import React, { useEffect, useState } from "react"
import logoImg from "../../assets/images/logo@2x.png"
import "/src/components/Header/Header.css"
import { trackingVideo } from "../../helpers/tracking-video"
import { getLinkWithQueryParams } from "../../utils/getLinkWithQueryParams"
import AppLink from "../AppLink"
import BrowserLink from "../BrowserLink"
import * as cl from "../TryButton/TryButton.module.css"
import SubMenu from "./SubMenu"
import trainingMenu from "../../data/menu/training.json"
import freeResourcesMenu from "../../data/menu/free-resources.json"
import analytics from '/src/utils/tracking'

const Header = ({ innerPage = true }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [isLogin, setIsLogin] = useState(false)

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.videoDATA = {
        data: {},
        trackingVideo: trackingVideo,
        name: undefined,
        prevTime: {},
        // actionEvent: {},
        isNeedSend: {},
      }

      navigator.sendBeacon && window.addEventListener("unload", logData, false)

      function logData() {
        navigator.sendBeacon("/log", () => {
          const videoName = window.videoDATA.name
          const videoData = window.videoDATA.data[videoName]

          if (window.videoDATA.isNeedSend[videoName]) {
            // you analytics.track
            console.log("video:pause", videoData)
          }
        })
      }
    }
  })

  useEffect(() => {
    if (document.cookie.includes("wordpress_logged_in_pocket_seises")) {
      setIsLogin(true)
    }
  }, [])

  const toggleNavbar = () => {
    setIsNavbarOpen(state => !state)
  }

  const goToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" })
  }

  const isShowNavbarClass = isNavbarOpen ? "_show" : ""

  const openUrl = (to, host = false, refQuery = false) => {
    const url = getLinkWithQueryParams(to, { host, refQuery })
    analytics.track('home:link', {
      href: to,
      ref: 'header__menu',
    });
    window.open(url, "_blank")
  }

  const isLoginBtn = isLogin ? (
    <BrowserLink
      href="/my-account/"
      target="_blank"
      rel="noreferrer"
      className="header__item"
      source="header__menu"
    >
      My Account
    </BrowserLink>
  ) : (
    <button
      role={"link"}
      onClick={() => openUrl("/login")}
      className="header__item"
    >
      Member Login
    </button>
  )

  return (
    <header className="header">
      <h1 className="header_title">Poker</h1>
      <div className="container flex-sb-c header-container">
        <button
          onClick={toggleNavbar}
          className={`header__toggle-navbar ${isShowNavbarClass}`}
        >
          <div className={`header__toggle-navbar-line ${isShowNavbarClass}`} />
        </button>

        <button onClick={goToTop}>
          <img
            src={logoImg}
            width="300"
            height="21"
            alt="Logo"
            className="header__logo"
          />
        </button>
        <nav className="header__menu">
          <ul>
            <li>
              <AppLink
                to="/"
                className="header__item"
                activeClassName="_active"
                source="header__menu"
              >
                Home
              </AppLink>
            </li>
            <li>
              <BrowserLink className="header__item">
                Training
              </BrowserLink>
              <div className="header__submenu">
                <SubMenu menu={trainingMenu} />
              </div>
            </li>
            <li>
              <BrowserLink
                href="/blog/category/poker-quizzes/"
                className="header__item"
                source="header__menu"
              >
                Quizzes
              </BrowserLink>
            </li>
            <li>
              <BrowserLink href="/blog/" className="header__item" source="header__menu">
                Articles
              </BrowserLink>
            </li>
            <li>
              <BrowserLink className="header__item" source="header__menu">
                Free Resources
              </BrowserLink>
              <div className="header__submenu">
                <SubMenu menu={freeResourcesMenu} />
              </div>
            </li>
            <li>{isLoginBtn}</li>
          </ul>
        </nav>
      </div>

      <div className={`mobile-navbar ${isShowNavbarClass}`}>
        <ul className="header__menu _mobile">
          <li>
            <AppLink to="/" className="header__item" activeClassName="_active" source="header__menu">
              Home
            </AppLink>
          </li>
          <li>
            <div className="header__submenu">
              <SubMenu menu={trainingMenu} />
            </div>
          </li>
          <li>
            <BrowserLink
              href="/blog/category/poker-quizzes/"
              className="header__item"
              source="header__menu"
            >
              Quizzes
            </BrowserLink>
          </li>
          <li>
            <button
              role={"link"}
              onClick={() =>
                openUrl("/blog", "https://pokercoaching.com", true)
              }
              className="header__item"
            >
              Articles
            </button>
          </li>
          <li>
            <div className="header__submenu">
              <SubMenu menu={freeResourcesMenu} />
            </div>
          </li>
          <li>{isLoginBtn}</li>
        </ul>
      </div>
    </header>
  )
}

export default Header
