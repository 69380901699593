import React from "react"
import { getLinkWithQueryParams } from "../../utils/getLinkWithQueryParams"
import analytics from '/src/utils/tracking'

const onClick = (href, source) => {
  analytics.track('home:link', {
    href: href,
    ref: source,
  });
}

const BrowserLink = props => {
  if (props.source) {
    return <a {...props} href={getLinkWithQueryParams(props.href)} onClick={() => onClick(props.href, props.source)} />
  } else {
    return <a {...props} href={getLinkWithQueryParams(props.href)} />
  }
}

export default BrowserLink
