export const getQueryParams = link => {
  if (typeof window === "undefined") return {};
  const currentUrl = link || window.location.href;

  const [baseLink, fragment] = currentUrl.split("#");
  const paramsString = baseLink.split("?")[1];
  const paramsArray = paramsString?.split("&") || [];

  const params = {};
  paramsArray.forEach(paramString => {
    const [key, value] = paramString.split("=");
    params[key] = value;
  })

  return { params, fragment };
};
