export function doubleTap(callback) {
  let taped = false;

  return (e) => {
    if(!taped) {
      taped = true;

      setTimeout(() => {
        taped = false;
      }, 300);

      return;
    }

    e.preventDefault();
    callback(e);
  }
}


export function pauseOtherVideo() {
  const videoEls = Array.from(document.querySelectorAll('video'));

  if(videoEls.length <= 1) {
    return;
  }

  videoEls.forEach((videoA) => {
    videoA.addEventListener('play', () => {
      videoEls.forEach((videoB) => {
        if(videoA !== videoB) {
          videoB.pause();
        }
      });
    });
  });
}


export function disableContextOnVideo() {
  const videoEls = Array.from(document.querySelectorAll('video'));

  videoEls.forEach((video) => {
    video.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  });
}


export function addSeekVideoInfo() {
  if(!window?.videojs) {
    return;
  }

  const players = window?.videojs.players;

  Object.values(players).forEach((player) => {
    const container = player.el_;
    const seekInfoEl = document.createElement('div');
    seekInfoEl.classList.add('seek-info');
    seekInfoEl.innerHTML = `
          <div class="seek-info-arrows">
            <span class="seek-info-base-arrow"></span>
            <span class="seek-info-base-arrow"></span>
            <span class="seek-info-base-arrow"></span>
          </div>
          <div class="seek-info-tooltip"><span class="seek-info-value"></span> s</div>
        `;

    container.appendChild(seekInfoEl);
  });
}


export function showSeekVideoInfo(player, side, seekValue) {
  showSeekVideoInfo.hideTimeoutId && clearTimeout(showSeekVideoInfo.hideTimeoutId);

  const seekInfo = player.el_.querySelector('.seek-info');

  seekInfo.classList.add('seek-info_visible');
  seekInfo.dataset.side = side;
  seekInfo.querySelector('.seek-info-value').textContent = seekValue;

  showSeekVideoInfo.hideTimeoutId = setTimeout(() => {
    seekInfo.classList.remove('seek-info_visible');
  }, 1000);
}


export function addSeekVideoButtons(seekValue) {
  if(!window?.videojs) {
    return;
  }

  function createButton(className, text) {
    const button = document.createElement("div");

    button.classList.add('vjs-seek-control', 'vjs-control', className);
    button.setAttribute('role', 'button');
    button.setAttribute('aria-live', 'polite');
    button.setAttribute('tabindex', '0');
    button.innerHTML = `
          <div class="vjs-control-content">
            <span class="vjs-control-text">${text}</span>
          </div>
        `;

    return button;
  }

  const players = window?.videojs.players;

  Object.values(players).forEach((player) => {
    const controlBarEL = player.el_.querySelector('.vjs-control-bar');
    const seekBackButton = createButton('vjs-seek-back-control', 'Seek back');
    const seekForwardButton = createButton('vjs-seek-forward-control', 'Seek forward');

    seekBackButton.addEventListener('click', () => {
      player.currentTime(player.currentTime() - seekValue);
      showSeekVideoInfo(player, 'back', seekValue);
    });

    seekForwardButton.addEventListener('click', () => {
      player.currentTime(player.currentTime() + seekValue);
      showSeekVideoInfo(player, 'forward', seekValue);
    });

    controlBarEL.insertBefore(seekBackButton, controlBarEL.firstElementChild.nextElementSibling);
    controlBarEL.insertBefore(seekForwardButton, controlBarEL.firstElementChild.nextElementSibling.nextElementSibling);
  });
}


export function addOverlayToVideo(options) {
  options.forEach(({ videoId, timeout, content, destroyVideo = false }) => {
    const player = window?.videojs(videoId);

    if(!player) {
      return;
    }

    const playerContainer = player.el_.parentElement;
    const overlayContainer = document.createElement('div');
    overlayContainer.classList.add('video-overlay');
    overlayContainer.innerHTML = content;
    let timeoutSecond;
    let checkTimeIdFunc;

    function playHandle() {
      checkTimeIdFunc = setInterval(() => {
        if(playerContainer.classList.contains('has-overlay')) {
          player.off('play', playHandle);
          player.off('pause', pauseHandle);
          return;
        }

        if(!timeoutSecond){
          const videoDuration = player.duration();
          timeoutSecond = /^\d{1,2}%$/.test(timeout)
            ? videoDuration / 100 * parseInt(timeout)
            : timeout;
        }

        if(player.currentTime() >= timeoutSecond) {
          playerContainer.classList.add('has-overlay');
          playerContainer.appendChild(overlayContainer);

          if(destroyVideo) {
            playerContainer.classList.add('destroyed');
            player.pause();
            playerContainer.removeChild(player.el_);
          }
        }
      }, 1000);
    }

    function pauseHandle() {
      clearInterval(checkTimeIdFunc);
    }

    player.on('play', playHandle);
    player.on('pause', pauseHandle);
  });
}


export function showPlayButtonOnPause() {
  if(!window?.videojs) {
    return;
  }

  const players = window?.videojs.players;

  Object.values(players).forEach((player) => {
    const playButtonEl = player.el_.querySelector('.vjs-swarmify-play-button');

    player.on('play', () => {
      playButtonEl.style.display = 'none';
    });

    player.on('pause', () => {
      playButtonEl.style.display = 'block';
    });
  });
}


export function seekVideoOnDoubleTap(seekValue) {
  if(!window?.videojs) {
    return;
  }

  const players = window?.videojs.players;

  function seekVideo(player, e) {
    const playerRect = player.el_.getBoundingClientRect();
    const videoHalfWidth = playerRect.width / 2;
    const x = e.changedTouches[0].clientX + (e.changedTouches[0].radiusX / 2) - playerRect.x;

    if(x <= videoHalfWidth) {
      player.currentTime(player.currentTime() - seekValue);
      showSeekVideoInfo(player, 'back', seekValue);
    } else {
      player.currentTime(player.currentTime() + seekValue);
      showSeekVideoInfo(player, 'forward', seekValue);
    }
  }

  Object.values(players).forEach((player) => {
    player.el_.addEventListener('touchend', doubleTap((e) => {
      seekVideo(player, e);
    }), false);
  });
}
