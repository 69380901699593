import { getQueryParams } from './getQueryParams';

export const getLinkWithQueryParams = (link, refParams = false) => {
  if (typeof window === 'undefined') return link;

  const currentUrl = window.location.href;
  const {params: locationParams} = getQueryParams();
  const {params: linkParams, fragment } = getQueryParams(link);

  if (!Object.keys(locationParams).length && !refParams) return link;

  let [linkWithoutQuery] = link.split('?');

  // link query params are higher priority so they should override location query params
  let paramsToSet = { ...locationParams, ...linkParams };

  // save logic that was previously used in setParamsToUrl function
  if (refParams) {
    const ref =
      (currentUrl.includes('ref=') &&
        currentUrl
          .match(/ref=([0-9a-zA-Z]*)/g, '$1')[0]
          .replace(/ref=/g, '')) ||
      '[ref]';
    const lp =
      (currentUrl.includes('lp=') &&
        currentUrl.match(/lp=([0-9a-zA-Z]*)/g, '$1')[0].replace(/lp=/g, '')) ||
      '[lp]';
    const campaign =
      currentUrl.includes('campaign=') &&
      currentUrl
        .match(/campaign=([0-9a-zA-Z]*)/g, '$1')[0]
        .replace(/campaign=/g, '');

    const { host, refQuery } = refParams;

    if (host) {
      linkWithoutQuery = `${host}${linkWithoutQuery}`;
    }
    if (refQuery) {
      paramsToSet = { ...paramsToSet, ref };
    } else {
      linkWithoutQuery += `/ref/${ref}`;
      delete paramsToSet.ref; //remove ref from paramsToSet as we do not want to override ref value
    }
    paramsToSet = { ...paramsToSet, lp, campaign };
  }

  const paramsEntries = Object.entries(paramsToSet).filter(
    ([, paramValue]) => !!paramValue
  )

  const url = paramsEntries.reduce((acc, curr, index) => {
    const [key, value] = curr;
    if (!value) return acc;
    if (!index) acc += '?';
    const isLastStep = index === paramsEntries.length - 1;
    let additionalString = '';
    if (isLastStep) {
      if (fragment) additionalString = `#${fragment}` 
    } else {
      additionalString = '&'
    }
    return (acc += `${key}=${value}${additionalString}`);
  }, linkWithoutQuery);

  return url;
};
