/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header"
import { Helmet } from "react-helmet"
import favicon from '../../images/favicon.ico'
import { useEffect } from "react"
import {
  addOverlayToVideo,
  addSeekVideoButtons,
  addSeekVideoInfo,
  disableContextOnVideo,
  pauseOtherVideo, seekVideoOnDoubleTap,
  showPlayButtonOnPause, showSeekVideoInfo
} from "../../helpers/video"
// import Footer from "../Footer"

const Layout = ({ innerPage, bodyClass, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    function handleLoadPage() {
      pauseOtherVideo();
      disableContextOnVideo();

      if(!window?.videojs) {
        return;
      }

      const players = window?.videojs.players;

      Object.values(players).forEach((player) => {
        player.ready(function() {
          this.hotkeys({
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false,
            enableVolumeScroll: false,
            rewindKey: (e) => {
              const isLeft = e.key === 'ArrowLeft';

              isLeft && showSeekVideoInfo(player, 'back', 5);
              return isLeft;
            },
            forwardKey: (e) => {
              const isRight = e.key === 'ArrowRight';

              isRight && showSeekVideoInfo(player, 'forward', 5);
              return isRight;
            }
          });
        });
      });

      pauseOtherVideo();
      addSeekVideoButtons(10);
      addSeekVideoInfo();

      /*const videoOverlayHTML = `
        <div>
          <button class="video-btn">Sign in</button>
          <button class="video-btn">Sign up</button>
        </div>
      `;

      addOverlayToVideo([
        {
          videoId: 'active_learning_video',
          timeout: 3,
          content: videoOverlayHTML,
          destroyVideo: true,
        },
        {
          videoId: 'card_video_1',
          timeout: '1%',
          content: videoOverlayHTML
        }
      ]);*/

      showPlayButtonOnPause();
      seekVideoOnDoubleTap(10);

      //Object.values(players)[0].el_.focus();

      // Fix window scroll
      //window.scroll(0, window.scrollY);
    }

    window.addEventListener('load', handleLoadPage);

    return () => {
      window.removeEventListener('load', handleLoadPage);
    };
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: bodyClass
        }}
      >
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header innerPage={innerPage} />
      <main>{children}</main>
      {/*<Footer />*/}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
