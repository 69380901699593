// window.videoDATA = {
//   data: {},
//   trackingVideo: trackingVideo,
//   name: undefined,
//   prevTime: {},
//   // actionEvent: {},
//   isNeedSend: {},
// }
import analytics from '/src/utils/tracking'

const setExtraVideoData = (id, action) => {
  // set last watching video
  window.videoDATA.name = id // name of video
  window.videoDATA.isNeedSend[id] = action === 'PLAY' // PLAY | PAUSE | END -- is need send analytics if user will want to close website
}

const setVideoData = (el, id, isPlay = false) => {
  const pathname = window.location.pathname;
  const postType = pathname.split('/');
  const percentsPlayed = Math.floor((el.currentTime / el.duration) * 100) || 0;

  const curData = {
    duration: el.duration || 0,
    path: pathname,
    percentsPlayed: percentsPlayed,
    title: id,
    totalViewedTime: el.currentTime,
    type: postType[postType.length - 1],
    videoPath: el.currentSrc,
  }

  if (isPlay) {
    window.videoDATA.prevTime[id] = el.currentTime
  } else {
    curData.totalViewedTime = el.currentTime - window.videoDATA.prevTime[id] || 0 // send parts of watched
  }

  window.videoDATA.data[id] = curData
}

const checkIsVideoEnded = (total, current) => Math.abs(total - current) < 0.1



export function trackingVideo(action, id) {
  if (!action || !id || typeof window === "undefined") return;
  // if video playing at first time
  // if (window.videoDATA?.data[id] === undefined) {
  //   window.videoDATA.data[id] = {}
  // }

  const element = document.getElementById(`${id}`)

  // check autoplay video
  if(element?.muted) return;
  if (element === null || !element?.firstChild) return;

  let el;
  // check is native video or smartvideo tag
  if(element.firstChild.nodeName === '#text') {
    el = element
  } else {
    el = element.firstChild
  }

  setExtraVideoData(id, action)


  // sending section
  if (action === 'END') {
    setVideoData(el, id, false)

    // send analytics
    analytics.track('home:video:end', window.videoDATA.data[id]);
    return
  }

  if (action === 'PLAY') {
    setVideoData(el, id, true)

    // send analytics
    analytics.track('home:video:play', window.videoDATA.data[id]);
    return
  }

  if (action === 'PAUSE') {
    if (checkIsVideoEnded(el.duration, el.currentTime)) return;

    setVideoData(el, id, false)

    // send analytics
    analytics.track('home:video:pause', window.videoDATA.data[id]);
    return
  }

  return;
}



// if the video is playing, but the user closes the tab
// normal browsers
// navigator.sendBeacon && window.addEventListener("unload", logData, false);

// function logData() {
//   navigator.sendBeacon("/log", () => {
//     const videoName = window.videoDATA.name
//     const videoData = window.videoDATA.data[videoName]

//     if(window.videoDATA.isNeedSend[videoName]) {
//       // you analytics.track
//       console.log('video:pause', videoData)
//     }
//   });
// }

// TODO
// IE -- example
// window.onbeforeunload && window.onbeforeunload = function(e) {
//   var dialogText = 'Dialog text here';
//   e.returnValue = dialogText;
//   return dialogText;
// };

