import React from "react"
import Link from "gatsby-link"
import { getLinkWithQueryParams } from "../../utils/getLinkWithQueryParams"
import analytics from '/src/utils/tracking'

const onClick = (to, source) => {
  analytics.track('home:applink', {
    to: to,
    ref: source,
  });
}

const AppLink = props => {
  if (props.source) {
    return <Link {...props}
                 to={getLinkWithQueryParams(props.to)}
                 onClick={() => onClick(props.to, props.source)}
    />
  } else {
    return <Link {...props} to={getLinkWithQueryParams(props.to)} />
  }
}

export default AppLink
